import { List, Datagrid, TextField, DeleteButton } from 'react-admin'

export const InvalidCustomerListAction = (props: object) => (
  <List {...props}>
    <Datagrid>
      <TextField source="recordId" label="ID" />
      <TextField source="customerId" />
      <TextField source="customerName" />
      <TextField source="tin" />
      <TextField source="bin" />
      <TextField source="status" />
      <DeleteButton />
    </Datagrid>
  </List>
)
