import { List, Datagrid, TextField, TextInput, DateField, DateInput } from 'react-admin'

export const customerFilters = [
  <TextInput label="CA ID" source="id" defaultValue="" alwaysOn />,
  <TextInput label="Affiliate partner id" source="affiliatePartner" alwaysOn />,
  <DateInput label="Created date" source="created" alwaysOn />,
  <DateInput label="Last change date" source="updated" alwaysOn />,
]

export const CustomerListAction = (props: object) => {
  return (
    <>
      <List filters={customerFilters} {...props}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="originId" label="Id" />
          <TextField source="customerName" />
          <TextField source="tin" />
          <TextField source="bin" />
          <TextField source="status" />
          <TextField source="affiliatePartner" />
          <DateField source="created" showTime={true} locales="cs-CZ" />
          <DateField source="updated" showTime={true} locales="cs-CZ" />
        </Datagrid>
      </List>
    </>
  )
}
