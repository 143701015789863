import { Resource } from 'react-admin'
import { HydraAdmin } from '@api-platform/admin'
import { CustomerListAction } from './actions/Customer/list'
import { CustomerEditAction } from './actions/Customer/edit'
import { InvalidCustomerListAction } from './actions/InvalidCustomer/list'
import authProvider from './authProvider/authProvider'
import { Login } from 'ra-auth-cognito'
import CustomDataProvider from './dataProvider/CustomDataProvider'

export const getApiUrl = (): string => {
  if (process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL) {
    return process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL
  }

  return 'CUSTOMER_ACCOUNT_API_URL_PLACEHOLDER'
}

const customDataProvider = CustomDataProvider(getApiUrl())

const app = () => (
  <HydraAdmin authProvider={authProvider} loginPage={Login} dataProvider={customDataProvider} entrypoint={getApiUrl()}>
    <Resource name={'customers'} list={CustomerListAction} edit={CustomerEditAction} />
    <Resource name={'invalid-customers'} list={InvalidCustomerListAction} />
  </HydraAdmin>
)

export default app
