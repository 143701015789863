import { CognitoAuthProvider } from 'ra-auth-cognito'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

const userPool = new CognitoUserPool({
  UserPoolId: 'USER_POOL_ID_PLACEHOLDER',
  ClientId: 'CLIENT_ID_PLACEHOLDER',
})

const authProvider = CognitoAuthProvider(userPool)

export const getAccessToken = async () => {
  const user = userPool.getCurrentUser()
  if (!user) {
    throw new Error('No current user')
  }
  return new Promise((resolve, reject) => {
    user.getSession((err: Error | null, session: any) => {
      if (err) {
        return reject(err)
      }
      if (!session.isValid()) {
        return reject(new Error('Session is not valid'))
      }
      resolve(session.getAccessToken().getJwtToken())
    })
  })
}

export default authProvider
